import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Site } from "../../types";

export interface SiteState {
  sites: Site[];
  selectedSite: string;
  selectedSiteLabel: string;
}

const initialState: SiteState = {
  sites: [],
  selectedSite: "",
  selectedSiteLabel: "",
};

const getInitialState = (): SiteState => {
  const sites = JSON.parse(localStorage.getItem("sites") || "[]");
  const selectedSite = localStorage.getItem("selectedSite") || "";
  const selectedSiteLabel = localStorage.getItem("selectedSiteLabel") || "";
  return {
    ...initialState,
    sites: sites,
    selectedSite: selectedSite,
    selectedSiteLabel,
  };
};

export const siteSlice = createSlice({
  name: "site",
  initialState: getInitialState,
  reducers: {
    setSites: (state, action: PayloadAction<{ data: Site[] }>) => {
      state.sites = action.payload.data;
    },
    setSelectedSite: (
      state,
      action: PayloadAction<{ data: string; label: string }>
    ) => {
      state.selectedSite = action.payload.data;
      state.selectedSiteLabel = action.payload.label;
    },
  },
});

export const { setSites, setSelectedSite } = siteSlice.actions;

export default siteSlice.reducer;
