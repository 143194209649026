import React, { useEffect, useMemo, useState } from "react";
import { Button, Modal, Progress, Statistic, Checkbox } from "antd";
import moment from "moment";
import { throttle } from "lodash";
import { useAppDispatch } from "../../../redux/hooks";
import { hideModal } from "../../../redux/reducers/modalSlice";

const { Countdown } = Statistic;

const TimerModal: React.FC<any> = ({ show, title, timer, callback }: any) => {
  const dispatch = useAppDispatch();
  const [started, setStarted] = useState(false);
  const [timerDone, setTimerDone] = useState(false);
  const [seconds, setSeconds] = useState<any>(null);
  const [total, setTotal] = useState(0);
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    if (started) {
      let seconds = 0;
      if (timer.minutes !== "" && !isNaN(timer.minutes)) {
        seconds = parseInt(timer.minutes) * 60;
      }
      if (timer.seconds !== "" && !isNaN(timer.seconds)) {
        seconds += parseInt(timer.seconds);
      }
      setTotal(seconds);
      setSeconds(moment().add(seconds, "seconds"));
    }
  }, [timer, setSeconds, started, setTotal]);

  const closeHandler = () => {
    if (callback) {
      callback();
    }
    dispatch(hideModal());
  };

  const timerFinish = () => {
    setTimerDone(true);
  };

  const timeChange = useMemo(
    () =>
      throttle((value: any) => {
        if (total > 0) {
          const percent = ((total - value / 1000) * 100) / total;
          setPercent(percent);
        }
      }, 1000),
    [setPercent, total]
  );

  return (
    <Modal
      visible={show}
      onCancel={closeHandler}
      closable={true}
      className="timer-modal"
      footer={null}
    >
      <div className="timer-content">
        <div className="timer-title">
          <Checkbox checked={timerDone} />
          Challenge
        </div>
        <div className="timer-subtitle">{title}</div>
        {timer?.image && (
          <img className="timer-image" src={timer?.image} alt="challenge" />
        )}
        {seconds && (
          <div className="timer-countdown">
            <Progress
              type="circle"
              percent={percent}
              showInfo={false}
              width={90}
              strokeColor={"#22DE99"}
            />
            <Countdown
              value={seconds}
              format="mm:ss"
              onFinish={timerFinish}
              onChange={timeChange}
            />
          </div>
        )}
        {started ? (
          <Button
            type="primary"
            className="cms-button"
            onClick={closeHandler}
            disabled={!timerDone}
          >
            Done
          </Button>
        ) : (
          <Button
            type="primary"
            className="cms-button"
            onClick={() => setStarted(true)}
          >
            Start
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default TimerModal;
