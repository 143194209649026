import { Modal, Input, Button, Select } from "antd";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { useAppDispatch } from "../../../redux/hooks";
import { useState } from "react";
import { errorToastMessage, toastMessage } from "../../../helpers/toastMessage";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import http from "../../../http";
import { toggleParticipantLoader } from "../../../redux/reducers/participantSlice";

type Props = {
  show: boolean;
  subjectId: string;
  userId: string;
  error: number | null;
};

const StudyRemoveModal = (props: Props) => {
  const [type, setType] = useState(
    typeof props.error === "number" ? props.error : 3
  );
  const [reason, setReason] = useState("");

  const dispatch = useAppDispatch();

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const changeHandler = (event: any) => {
    setReason(event.target.value);
  };

  const submitHandler = async () => {
    try {
      dispatch(setAppLoader(true));
      await http.patch(`/users/${props.userId}/error-track`, {
        type: type === 3 ? null : type,
        reason: reason || null,
      });
      toastMessage("success", "Participant status updated successfully");
      closeHandler();
      dispatch(toggleParticipantLoader());
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal
      title={"Set Study Status for " + props.subjectId}
      visible={props.show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div className="mb-3">
        <div>
          <div className="text-secondary font-m fw-medium mb-1">Status</div>
          <Select
            className="custom-select-field"
            size="large"
            value={type}
            onChange={(val) => setType(val)}
            placeholder="Select Type"
          >
            <Select.Option key={3} value={3}>
              Active
            </Select.Option>
            <Select.Option key={0} value={0}>
              Recruitment Error
            </Select.Option>
            <Select.Option key={1} value={1}>
              Dropout
            </Select.Option>
            <Select.Option key={2} value={2}>
              Non-Compliant
            </Select.Option>
          </Select>
        </div>
      </div>
      <div>
        <div className="col-md-12 mb-4">
          <div className="form-group">
            <div className="text-secondary font-m fw-medium mb-1">Reason</div>
            <Input
              className="singer-input"
              value={reason}
              placeholder="Enter reason here..."
              onChange={changeHandler}
            />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <Button
          type="primary"
          style={{ height: "40px", borderRadius: "12px" }}
          className="me-4"
          onClick={submitHandler}
        >
          <span className="fw-semibold font-sm">Save</span>
        </Button>
        <Button
          style={{ height: "40px", borderRadius: "12px" }}
          className="me-4"
          onClick={closeHandler}
        >
          <span className="fw-semibold font-sm">Cancel</span>
        </Button>
      </div>
    </Modal>
  );
};

export default StudyRemoveModal;
