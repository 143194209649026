import { Modal, Button, Select, Spin, Switch } from "antd";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { useAppDispatch } from "../../../redux/hooks";
import { useMemo, useState } from "react";
import { errorToastMessage, toastMessage } from "../../../helpers/toastMessage";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import { debounce } from "lodash";
import http from "../../../http";

type Props = {
  show: boolean;
  value: any;
  successCallback: Function;
};

const RandomizationEntryModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const [related, setRelated] = useState(false);
  const [relationshipId, setRelationshipId] = useState<string | undefined>();
  const [type, setType] = useState("spouse");
  const [fetching, setFetching] = useState<boolean>(false);
  const [options, setOptions] = useState<any[]>();

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const submitHandler = async () => {
    try {
      dispatch(setAppLoader(true));
      if (related) {
        if (!relationshipId) {
          dispatch(setAppLoader(false));
          toastMessage("warning", "Please selected the related participant");
          return;
        }
        props.successCallback(props.value, undefined, false, {
          relationshipType: type,
          relationshipId: relationshipId,
        });
      } else {
        props.successCallback(props.value);
      }
      closeHandler();
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = async (value: string) => {
      try {
        setOptions([]);
        let val: any = value.replace(/[nuhs]/gi, "");
        if (!val) {
          return;
        }
        val = Number(val);
        setFetching(true);
        let url = `/users/list?stage=1&subjectId=${val}`;
        const res = await http.get(url);
        const data = res.data.data;
        const users = data.users.map((user: any) => {
          return {
            value: user.id,
            label: user.nickname
              ? user?.participant?.subjectId + `(${user.nickname})`
              : user?.participant?.subjectId,
          };
        });
        setOptions(users);
        setFetching(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setFetching(false);
      }
    };
    return debounce(loadOptions, 500);
  }, [setFetching, setOptions]);

  return (
    <Modal
      title="Randomization details"
      visible={props.show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div>
        <div className="mb-5">
          <div className="d-flex align-items-center">
            <Switch
              checked={related}
              onChange={(checked) => setRelated(checked)}
            />
            <div className="font-sm fw-semibold ms-3">
              Is the Subject related to other participants of the study
            </div>
          </div>
        </div>
        {related && (
          <>
            <div className="mb-3">
              <div>
                <div className="text-secondary font-m fw-medium mb-1">
                  Relationship type
                </div>
                <Select
                  className="custom-select-field"
                  size="large"
                  value={type}
                  onChange={(val) => setType(val)}
                  placeholder="Select language"
                >
                  <Select.Option key="spouse" value="spouse">
                    Spouse
                  </Select.Option>
                  <Select.Option key="sibling" value="sibling">
                    Sibling
                  </Select.Option>
                </Select>
              </div>
            </div>
            <div>
              <div className="text-secondary font-m fw-medium mb-1">
                Search for Participants
              </div>
              <Select
                showSearch
                filterOption={false}
                onSearch={debounceFetcher}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                options={options}
                className="custom-select-field"
                size="large"
                value={relationshipId}
                onChange={(val) => setRelationshipId(val)}
                placeholder="Select Participant"
              />
            </div>
          </>
        )}
      </div>
      <div className="d-flex align-items-center mt-4">
        <Button
          type="primary"
          style={{ height: "40px", borderRadius: "12px" }}
          className="me-4"
          onClick={submitHandler}
        >
          <span className="fw-semibold font-sm">Submit</span>
        </Button>
        <Button
          style={{ height: "40px", borderRadius: "12px" }}
          className="me-4"
          onClick={closeHandler}
        >
          <span className="fw-semibold font-sm">Cancel</span>
        </Button>
      </div>
    </Modal>
  );
};

export default RandomizationEntryModal;
