import moment, { Moment } from "moment";

export const appointmentQueryConstructor: (tab: string) => string = (tab) => {
  const today_from_date = moment().startOf("day").utc().toISOString();
  const today_to_date = moment().endOf("day").utc().toISOString();
  const week_from_date = today_from_date;
  const week_to_date = moment().add(7, "days").endOf("day").utc().toISOString();
  const query = `?tab=${tab}&today_from_date=${today_from_date}&today_to_date=${today_to_date}&week_from_date=${week_from_date}&week_to_date=${week_to_date}`;
  return query;
};

export const appointmentPerTimePeriodConstructor: (tab: string) => string = (
  tab
) => {
  let from_date = moment().startOf("day");
  let to_date = moment();
  if (tab === "week") {
    to_date.add("1", "week").endOf("day");
  } else if (tab === "month") {
    to_date.add("1", "month").endOf("day");
  } else {
    to_date.endOf("day");
  }
  let query = `?tab=${tab}&from_date=${from_date.toISOString()}`;
  if (tab !== "upcoming") {
    query += `&to_date=${to_date.toISOString()}`;
  }
  return query;
};

export const timepickerOptions = () => {
  const timePicker: number[] = [];

  for (let i = 0; i < 48; i++) {
    timePicker.push(i * 30 * 60);
  }
  return timePicker;
};

export const appointmentAnalyticsPeriodConstructor: (tab: string) => any = (
  tab
) => {
  let days = 0;
  if (tab === "week") {
    days = 6;
  } else if (tab === "month") {
    days = 30;
  }
  const from_date = moment().subtract(days, "days").startOf("day").utc();
  const to_date = moment().endOf("day").utc();
  const query = `?from_date=${from_date.toISOString()}&to_date=${to_date.toISOString()}`;
  return {
    query,
    from_date: from_date,
    to_date: to_date,
  };
};

export const getCalendarParams = (view: string, date: Moment) => {
  if (view === "day") {
    return {
      label: date.format("MMMM DD, YYYY"),
      startDate: date.clone().startOf("day"),
      endDate: date.clone().endOf("day"),
    };
  } else if (view === "month") {
    return {
      label: date.format("MMMM YYYY"),
      startDate: date.clone().startOf("month"),
      endDate: date.clone().endOf("month"),
    };
  } else if (view === "week") {
    const startDate = date.clone().startOf("week");
    const endDate = startDate.clone().add(1, "week");
    const startYear = startDate.format("YYYY");
    const endYear = endDate.format("YYYY");
    const sameYear = startYear === endYear;
    const startMonth = startDate.format("MMMM");
    const endMonth = endDate.format("MMMM");
    let str = "";
    if (startMonth === endMonth) {
      str =
        startDate.format("MMMM DD") +
        " - " +
        endDate.format("DD, ") +
        startYear;
    } else {
      str =
        startDate.format("MMMM DD" + (sameYear ? "" : ", YYYY")) +
        " - " +
        endDate.format("MMMM DD, YYYY");
    }
    return {
      label: str,
      startDate,
      endDate,
    };
  } else {
    return null;
  }
};

export const appointmentMap: any = {
  outcome_visit: {
    label: "Outcome visit",
    key: "outcome_visit",
    color: "#1C64F2",
    linkRequired: false,
  },
  clinic_visit: {
    label: "Clinic visit",
    key: "clinic_visit",
    color: "#F05252",
    linkRequired: false,
  },
  exercise_group: {
    label: "Exercise Group (Zoom)",
    key: "exercise_group",
    color: "#7E3AF2",
    linkRequired: true,
  },
  gym_exercise: {
    label: "Gym Exercise",
    key: "gym_exercise",
    color: "#D61F69",
    linkRequired: false,
  },
  cognitive_group_talk: {
    label: "Cognitive Group Talk (Zoom)",
    key: "cognitive_group_talk",
    color: "#FF5A1F",
    linkRequired: true,
  },
  cognitive_training: {
    label: "Cognitive Training (Zoom)",
    key: "cognitive_training",
    color: "#FACA15",
    linkRequired: true,
  },
  diet_group_talk: {
    label: "Diet Group Talk (Zoom)",
    key: "diet_group_talk",
    color: "#0E9F6E",
    linkRequired: true,
  },
  diet_individual_consultation: {
    label: "Diet individual consultation (Zoom)",
    key: "diet_individual_consultation",
    color: "#5850EC",
    linkRequired: true,
  },
  technical_check: {
    label: "Technical check (Zoom)",
    key: "technical_check",
    color: "#4B5563",
    linkRequired: true,
  },
  others: {
    label: "Others",
    key: "others",
    color: "#00B9FC",
    linkRequired: false,
  },
};

export const appointmentMapArray: any[] = Object.values(appointmentMap);
