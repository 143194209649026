import { Modal, Button } from "antd";
import { hideModal, setModalDetails } from "../../../redux/reducers/modalSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import { errorToastMessage, toastMessage } from "../../../helpers/toastMessage";
import http from "../../../http";
import { appointmentMap } from "../../../helpers/Appointment";
import { useEffect, useState } from "react";
import moment from "moment";
import { canAmendAppointments } from "../../../helpers/roles";

const ViewAppointmentModal = () => {
  const dispatch = useAppDispatch();
  const [appointment, setAppointment] = useState<any>({});
  const { show, callback, id } = useAppSelector(
    (state) => state.modal.modalProps
  );
  const role = useAppSelector((state) => state.user.role);
  const [apptAccess] = useState(canAmendAppointments(role));

  useEffect(() => {
    const fetchAdmin = async (id: string) => {
      try {
        dispatch(setAppLoader(true));
        const response = await http.get(`/ap/${id}`);
        const event = response.data.data;
        const dateStr = moment(event.scheduledStartTime).format("DD/MM/YYYY");
        const startStr = moment(event.scheduledStartTime).format("hh:mm A");
        const timeStr =
          startStr + " - " + moment(event.scheduledEndTime).format("hh:mm A");
        const upcoming = moment().isSameOrBefore(
          moment(event.scheduledStartTime)
        );
        setAppointment({
          id: event.id,
          scheduledStartTime: new Date(event.scheduledStartTime),
          scheduledEndTime: new Date(event.scheduledEndTime),
          actualTitle: event.title,
          title: event.title,
          inviters: event.inviters,
          invitees: event.invitees,
          joinLink: event.joinLink,
          notes: event.notes,
          type: event.type,
          repeating: event.repeating || false,
          group: event.participantGroup,
          timeStr,
          dateStr,
          upcoming,
        });
        dispatch(setAppLoader(false));
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    };
    fetchAdmin(id);
  }, [setAppointment, dispatch, id]);

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const cancelAppointment = async () => {
    if (appointment.repeating) {
      dispatch(
        setModalDetails({
          type: "CANCEL_APPOINTMENT",
          modalProps: {
            show: true,
            appointment: appointment,
            callback,
          },
        })
      );
    } else {
      try {
        dispatch(setAppLoader(true));
        await http.patch(`/ap/${appointment?.id}/cancel`);
        toastMessage("success", "Appointment cancelled successfully");
        closeHandler();
        callback();
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    }
  };

  const enterLink = () => {
    const newWindow = window.open(
      appointment.joinLink,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  const rescheduleAppointment = () => {
    dispatch(
      setModalDetails({
        type: "RESCHEDULE_APPOINTMENT",
        modalProps: {
          show: true,
          id: appointment.id,
          callback,
        },
      })
    );
  };

  return (
    <Modal
      title={"View Appointment"}
      visible={show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div style={{ minHeight: "25vh" }} className="d-flex flex-column">
        <div className="d-flex mb-2">
          <div className="text-secondary font-m fw-medium me-3">Title:</div>
          <div className="text-secondary font-m">
            {appointment && appointment?.actualTitle}
          </div>
        </div>
        <div className="d-flex mb-2">
          <div className="text-secondary font-m fw-medium me-3">
            Scheduled time:
          </div>
          <div className="text-secondary font-m">
            {appointment?.dateStr &&
              appointment?.dateStr + " " + appointment?.timeStr}
          </div>
        </div>
        <div className="d-flex mb-2">
          <div className="text-secondary font-m fw-medium me-3">
            Appointment Type:
          </div>
          <div className="text-secondary font-m">
            {appointment.type && appointmentMap[appointment?.type]?.label}
          </div>
        </div>
        {appointment.group && (
          <>
            <div className="d-flex mb-2">
              <div className="text-secondary font-m fw-medium me-3">
                Group name:
              </div>
              <div className="text-secondary font-m">
                {appointment.group?.name}
              </div>
            </div>
            <div className="d-flex mb-2">
              <div className="text-secondary font-m fw-medium me-3">
                Participants:
              </div>
              <div className="text-secondary font-m">
                {appointment.group?.participants?.map(
                  (user: any, index: number) => {
                    return (
                      <span key={user.id}>
                        {user?.participant?.subjectId +
                          (user?.nickname ? `(${user.nickname})` : "") +
                          (index === appointment.group?.participants?.length - 1
                            ? ""
                            : ", ")}
                      </span>
                    );
                  }
                )}
              </div>
            </div>
          </>
        )}
        {appointment.invitees?.length > 0 && !appointment?.group && (
          <div className="d-flex mb-2">
            <div className="text-secondary font-m fw-medium me-3">
              Participants:
            </div>
            <div className="text-secondary font-m">
              {appointment.invitees?.map((user: any, index: number) => {
                return (
                  <span key={user?.invitee?.id}>
                    {user?.invitee?.participant?.subjectId +
                      (user?.invitee?.nickname
                        ? `(${user?.invitee?.nickname})`
                        : "") +
                      (index === appointment.invitees?.length - 1 ? "" : ", ")}
                  </span>
                );
              })}
            </div>
          </div>
        )}
        {appointment.inviters?.length > 0 && (
          <div className="d-flex mb-2">
            <div className="text-secondary font-m fw-medium me-3">
              Inviters:
            </div>
            <div className="text-secondary font-m">
              {appointment.inviters?.map((user: any, index: number) => {
                return (
                  <span key={user?.inviter?.id}>
                    {user?.inviter?.firstName +
                      " " +
                      user?.inviter?.lastName +
                      (index === appointment.inviters?.length - 1 ? "" : ", ")}
                  </span>
                );
              })}
            </div>
          </div>
        )}
        {appointment.notes && (
          <div className="d-flex mb-2">
            <div className="text-secondary font-m fw-medium me-3">
              Description:
            </div>
            <div className="text-secondary font-m">{appointment.notes}</div>
          </div>
        )}
        <div className="mb-3"></div>
        <div className="d-flex" style={{ marginTop: "auto" }}>
          {appointment?.joinLink && (
            <div>
              <Button
                type="primary"
                onClick={enterLink}
                style={{ height: "40px", borderRadius: "12px" }}
                className="me-4"
              >
                <span className="fw-semibold font-sm">Join</span>
              </Button>
            </div>
          )}
          {apptAccess && appointment?.upcoming && (
            <Button
              type="primary"
              onClick={rescheduleAppointment}
              style={{ height: "40px", borderRadius: "12px" }}
              className="me-4"
            >
              <span className="fw-semibold font-sm">Edit</span>
            </Button>
          )}
          <Button
            type="primary"
            onClick={closeHandler}
            style={{ height: "40px", borderRadius: "12px" }}
            className="me-4"
          >
            <span className="fw-semibold font-sm">Close</span>
          </Button>
          {apptAccess && appointment?.upcoming && (
            <Button
              style={{ height: "40px", borderRadius: "12px" }}
              className="me-4"
              onClick={cancelAppointment}
            >
              <span className="fw-semibold font-sm">Cancel Appointment</span>
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ViewAppointmentModal;
