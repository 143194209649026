import { Image, Modal, Table } from "antd";
import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { hideModal } from "../../../redux/reducers/modalSlice";

const FoodSubmissionLog = () => {
  const dispatch = useAppDispatch();
  const { show, versions } = useAppSelector((state) => state.modal.modalProps);

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const columns: any[] = useMemo(() => {
    return [
      {
        title: "Revision Done By",
        dataIndex: "user",
        key: "user",
      },
      {
        title: "Revision Date",
        dataIndex: "updatedDate",
        key: "updatedDate",
      },
      {
        title: "Reason",
        dataIndex: "reason",
        key: "reason",
      },
      {
        title: "Previous Value",
        dataIndex: "prevValue",
        key: "prevValue",
        render: (data: any) => {
          return (
            <>
              {data ? (
                <>
                  {data.imageUrl && (
                    <div>
                      <Image
                        src={data?.imageUrl}
                        height={100}
                        width={100}
                        placeholder={"Preview"}
                      />
                    </div>
                  )}
                  <div className="fw-semibold font-m text-prime mb-2">
                    {data?.title}
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      Place Prepared:
                    </span>
                    <span>{data?.isHome ? "Home" : "Outside"}</span>
                  </div>
                  {data?.preparation && (
                    <div className="mb-2">
                      <span className="fw-medium text-prime me-1">
                        Preparation:
                      </span>
                      <span className="text-prime">{data?.preparation}</span>
                    </div>
                  )}
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">Serving:</span>
                    <span>{`${data?.servingType} * ${data.quantity}`}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">Time:</span>
                    <span>{data?.date + " " + data?.time}</span>
                  </div>
                </>
              ) : (
                "-"
              )}
            </>
          );
        },
      },
      {
        title: "New Value",
        dataIndex: "newValue",
        key: "newValue",
        render: (data: any) => {
          return (
            <>
              {data ? (
                <>
                  {data.imageUrl && (
                    <div>
                      <Image
                        src={data?.imageUrl}
                        height={100}
                        width={100}
                        placeholder={"Preview"}
                      />
                    </div>
                  )}
                  <div className="fw-semibold font-m text-prime mb-2">
                    {data?.title}
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      Place Prepared:
                    </span>
                    <span>{data?.isHome ? "Home" : "Outside"}</span>
                  </div>
                  {data?.preparation && (
                    <div className="mb-2">
                      <span className="fw-medium text-prime me-1">
                        Preparation:
                      </span>
                      <span className="text-prime">{data?.preparation}</span>
                    </div>
                  )}
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">Serving:</span>
                    <span>{`${data?.servingType || "-"} * ${
                      data.quantity || "-"
                    }`}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">Time:</span>
                    <span>{data?.date + " " + data?.time}</span>
                  </div>
                </>
              ) : (
                "-"
              )}
            </>
          );
        },
      },
    ];
  }, []);

  return (
    <Modal
      title={"Food Submission Logs"}
      visible={show}
      onCancel={closeHandler}
      width={"60rem"}
      footer={null}
    >
      <Table
        dataSource={versions}
        columns={columns}
        className="singer-custom-table"
        rowKey={(record) => record.id}
        pagination={false}
      />
    </Modal>
  );
};

export default FoodSubmissionLog;
