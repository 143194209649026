import { Modal, Table } from "antd";
import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { hideModal } from "../../../redux/reducers/modalSlice";

const ExerciseSubmissionLog = () => {
  const dispatch = useAppDispatch();
  const { show, versions } = useAppSelector((state) => state.modal.modalProps);

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const columns: any[] = useMemo(() => {
    return [
      {
        title: "Revision Done By",
        dataIndex: "user",
        key: "user",
      },
      {
        title: "Revision Date",
        dataIndex: "updatedDate",
        key: "updatedDate",
      },
      {
        title: "Reason",
        dataIndex: "reason",
        key: "reason",
      },
      {
        title: "Previous Value",
        dataIndex: "prevValue",
        key: "prevValue",
        render: (data: any) => {
          return (
            <>
              {data ? (
                <>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">Title:</span>
                    <span className="text-prime">{data?.title}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">Category:</span>
                    <span className="text-prime">{data?.category}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">Date:</span>
                    <span className="text-prime">
                      {data?.date + " " + data?.time}
                    </span>
                  </div>
                  {data?.notes && (
                    <div className="mb-2">
                      <span className="fw-medium text-prime me-1">Notes:</span>
                      <span className="text-prime">{data?.notes}</span>
                    </div>
                  )}
                  <div>
                    {data?.data &&
                      Object.entries(data?.data).map(([type, value]: any) => {
                        return (
                          <div key={type}>
                            <span
                              style={{ textTransform: "capitalize" }}
                              className="fw-semibold"
                            >
                              {type}
                            </span>
                            <span> - </span>
                            <span>{value}</span>
                          </div>
                        );
                      })}
                  </div>
                </>
              ) : (
                "-"
              )}
            </>
          );
        },
      },
      {
        title: "New Value",
        dataIndex: "newValue",
        key: "newValue",
        render: (data: any) => {
          return (
            <>
              {data ? (
                <>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">Title:</span>
                    <span className="text-prime">{data?.title}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">Category:</span>
                    <span className="text-prime">{data?.category}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">Date:</span>
                    <span className="text-prime">
                      {data?.date + " " + data?.time}
                    </span>
                  </div>
                  {data?.notes && (
                    <div className="mb-2">
                      <span className="fw-medium text-prime me-1">Notes:</span>
                      <span className="text-prime">{data?.notes}</span>
                    </div>
                  )}
                  <div>
                    {data?.data &&
                      Object.entries(data?.data).map(([type, value]: any) => {
                        return (
                          <div key={type}>
                            <span
                              style={{ textTransform: "capitalize" }}
                              className="fw-semibold"
                            >
                              {type}
                            </span>
                            <span> - </span>
                            <span>{value}</span>
                          </div>
                        );
                      })}
                  </div>
                </>
              ) : (
                "-"
              )}
            </>
          );
        },
      },
    ];
  }, []);

  return (
    <Modal
      title={"Exercise Submission Logs"}
      visible={show}
      onCancel={closeHandler}
      width={"60rem"}
      footer={null}
    >
      <Table
        dataSource={versions}
        columns={columns}
        className="singer-custom-table"
        rowKey={(record) => record.id}
        pagination={false}
      />
    </Modal>
  );
};

export default ExerciseSubmissionLog;
