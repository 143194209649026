import { AxiosResponse } from "axios";
import http from "../../http";
import { loginForm } from "../../types";
import { AppThunk } from "../store";
import { toastMessage } from "../../helpers/toastMessage";
import { app, auth } from "../../firebase";
import {
  setAuthenticated,
  setRole,
  setUserName,
  userLogout,
} from "../reducers/userSlice";
import jwt_decode from "jwt-decode";
import { signInWithCustomToken } from "firebase/auth";
import { getMessaging, getToken, isSupported } from "firebase/messaging";
import { setSelectedSite, setSites } from "../reducers/siteSlice";

export const decodeToken = () => {
  const token = localStorage.getItem("singer-accessToken");
  if (token) {
    let decoded;
    try {
      decoded = jwt_decode(token);
    } catch (err) {
    } finally {
      return decoded;
    }
  }
  return null;
};

export const loginAction =
  (form: loginForm): AppThunk =>
  async (dispatch) => {
    const res: AxiosResponse = await http.post("/auth/login", form);
    const response = res.data.data;
    const name = `${response.firstName} ${response.lastName}`;
    const { id, token, profileImage, dbToken, refreshToken, sites } = response;
    const img = profileImage !== null ? profileImage : "";
    localStorage.setItem("singer-accessToken", token);
    localStorage.setItem("singer-refresh-token", refreshToken);
    localStorage.setItem("userId", id);
    localStorage.setItem("fullName", name);
    localStorage.setItem("profileImage", img);
    localStorage.setItem("sites", JSON.stringify(sites));
    localStorage.setItem("selectedSite", sites[0]?.id);
    localStorage.setItem("selectedSiteLabel", sites[0]?.name);
    dispatch(setSites({ data: sites }));
    dispatch(setSelectedSite({ data: sites[0]?.id, label: sites[0]?.name }));
    const decoded: any = decodeToken();
    if (decoded) {
      dispatch(setUserName(name));
      dispatch(setRole(decoded?.role));
      dispatch(setAuthenticated(true));
      signInWithCustomToken(auth, dbToken)
        .then(() => {})
        .catch((err) => {
          console.log(err as Error);
        });
    } else {
      localStorage.clear();
      throw new Error("Something went wrong");
    }
  };

export const initialiseFCM = (): AppThunk => async (dispatch) => {
  const userId = localStorage.getItem("userId");
  const vapidKey = process.env.REACT_APP_FCM_KEY;
  const messaging = await isSupported()
    .then((support) => {
      if (support) {
        return getMessaging(app);
      } else {
        return false;
      }
    })
    .catch(() => {
      return null;
    });

  if (!userId || !vapidKey || !messaging) {
    return;
  }
  getToken(messaging, { vapidKey: vapidKey })
    .then((currentToken) => {
      if (currentToken) {
        const registeredToken = localStorage.getItem("fcm_token");
        if (currentToken !== registeredToken) {
          localStorage.setItem("fcm_token", currentToken);
          http
            .patch(`/admins/${userId}`, {
              fcmToken: currentToken,
            })
            .then(() => {})
            .catch((err) => console.log(err));
        }
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

export const otpVerifyAction =
  (data: any): AppThunk =>
  async (dispatch) => {
    await http.post("/auth/signup/verify_otp", data);
    toastMessage("success", "OTP has Verified Successfully!! Please Login");
  };

export const resendOtp =
  (data: any): AppThunk =>
  async (dispatch) => {
    const res: AxiosResponse = await http.post("auth/signup/resend_otp", data);
    toastMessage("success", res.data.message);
  };

export const setYourPassword =
  (data: any): AppThunk =>
  async (dispatch) => {
    const res: AxiosResponse = await http.put(`/auth/set_password`, data);
    toastMessage("success", res.data.message);
  };

export const resetPassword =
  (data: any): AppThunk =>
  async (dispatch) => {
    const res: AxiosResponse = await http.put(
      "/auth/forgot_password/reset",
      data
    );
    toastMessage("success", res.data.message);
  };

export const firebaseLogout = () => {
  try {
    auth
      .signOut()
      .then()
      .catch((err) => {
        console.log(err);
      });
  } catch (err) {}
};

export const authLogout = (): AppThunk => async (dispatch) => {
  try {
    auth
      .signOut()
      .then()
      .catch((err) => {
        console.log(err);
      });
    await http.post("/auth/logout", {
      fcmToken: localStorage.getItem("fcm_token") || undefined,
    });
  } catch (err) {
  } finally {
    localStorage.clear();
    dispatch(userLogout());
  }
};

export const forgotPassword =
  (data: any): AppThunk =>
  async (dispatch) => {
    const res: AxiosResponse = await http.post("/auth/forgot_password", data);
    toastMessage("success", res.data.message);
  };

export const verifyEmail =
  (body: any): AppThunk =>
  async (dispatch) => {
    await http.post("/auth/verify_email", body);
  };

export const sleep = async (ms: number) => {
  await new Promise((res: Function, rej) => {
    setTimeout(() => {
      res();
    }, ms);
  });
};

export const changePassword =
  (data: any): AppThunk =>
  async (dispatch) => {
    const res: AxiosResponse = await http.put("/auth/change_password", data);
    toastMessage("success", res.data.message);
  };

// export const getUserInfo =
//   (id: any): AppThunk =>
//   async (dispatch) => {
//     try {
//       dispatch(setAppLoader(true));
//       const res: AxiosResponse = await http.get(`/users/${id}`);
//       dispatch(setUserInfo(res.data.data));
//       dispatch(setAppLoader(false));
//     } catch (err) {
//       dispatch(setAppLoader(false));
//       errorToastMessage(err);
//       history.push("/dashboard");
//     }
//   };

// export const editProfile =
//   (userId: any, body: any): AppThunk =>
//   async (dispatch) => {
//     try {
//       const res: AxiosResponse = await http.put(`/users/${userId}`, body);
//       dispatch(getUserInfo(userId));
//       localStorage.setItem(
//         "fullName",
//         `${res.data.data.firstName} ${res.data.data.lastName}`
//       );
//       localStorage.setItem("profileImage", res.data.data.profileImage);
//       history.push("/dashboard");
//       toastMessage("success", res.data.message);
//     } catch (err) {
//       dispatch(setAppLoader(false));
//       errorToastMessage(err);
//     }
//   };
