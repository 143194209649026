import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AdminUserPayload,
  ParticipantGroupPayload,
  UserPayload,
} from "../../types";

export interface ParticipantState {
  urlLoaded: boolean;
  searchTerm: string;
  searchType: string;
  screenedParticipantUsers: any[];
  screenedParticipantCount: number;
  preScreenedParticipantUsers: any[];
  preScreenedParticipantCount: number;
  groupCount: number;
  groups: any[];
  filterParticipants: any[];
  filterParticipantsCount: number;
  toggleLoader: boolean;
  selectedParticipants: string[];
  headers: any[];
  crfHeaders: any[];
  proHeaders: any[];
  currentPage: number;
  filter: string;
  selectedTab: string;
  sort: {
    orderBy: string;
    sortBy: string;
  };
}

const initialState: ParticipantState = {
  urlLoaded: false,
  searchTerm: "",
  searchType: "screenId",
  screenedParticipantUsers: [],
  screenedParticipantCount: 0,
  preScreenedParticipantUsers: [],
  preScreenedParticipantCount: 0,
  toggleLoader: false,
  groups: [],
  groupCount: 0,
  filterParticipants: [],
  filterParticipantsCount: 0,
  selectedParticipants: [],
  headers: [],
  crfHeaders: [],
  proHeaders: [],
  currentPage: 1,
  filter: "",
  selectedTab: "pre-screened",
  sort: {
    orderBy: "asc",
    sortBy: "screenId",
  },
};

export const participantSlice = createSlice({
  name: "participant",
  initialState,
  reducers: {
    setParticipantSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
      state.currentPage = 1;
      state.filter = "";
    },
    setParticipantSearchType: (
      state,
      action: PayloadAction<{ type: string }>
    ) => {
      state.searchType = action.payload.type;
    },
    setScreenedParticipantUsers: (
      state,
      action: PayloadAction<{
        users: any[];
        count: number;
        proHeaders: any[];
        crfHeaders: any[];
      }>
    ) => {
      state.screenedParticipantUsers = action.payload.users;
      state.screenedParticipantCount = action.payload.count;
      state.proHeaders = action.payload.proHeaders;
      state.crfHeaders = action.payload.crfHeaders;
    },
    setParticipantGroups: (
      state,
      action: PayloadAction<ParticipantGroupPayload>
    ) => {
      state.groups = action.payload.groups;
      state.groupCount = action.payload.count;
    },
    setFilterParticipants: (state, action: PayloadAction<AdminUserPayload>) => {
      state.filterParticipants = action.payload.users;
      state.filterParticipantsCount = action.payload.count;
    },
    setPreScreenedParticipantUsers: (
      state,
      action: PayloadAction<UserPayload>
    ) => {
      state.preScreenedParticipantUsers = action.payload.users;
      state.preScreenedParticipantCount = action.payload.count;
      state.headers = action.payload.headers;
    },
    toggleParticipantLoader: (state) => {
      state.toggleLoader = !state.toggleLoader;
    },
    setSelectedParticipants: (state, action: PayloadAction<string[]>) => {
      state.selectedParticipants = action.payload;
    },
    setParticipantsTab: (state, action: PayloadAction<string>) => {
      state.filter = "";
      state.currentPage = 1;
      state.searchTerm = "";
      state.selectedParticipants = [];
      state.sort = {
        orderBy: "asc",
        sortBy: "screenId",
      };
      state.searchType = action.payload === "group" ? "general" : "screenId";
      state.selectedTab = action.payload;
    },
    setParticipantsFilter: (state, action: PayloadAction<string>) => {
      state.filter = action.payload;
    },
    setParticipantsSort: (
      state,
      action: PayloadAction<{ sort: { orderBy: string; sortBy: string } }>
    ) => {
      state.sort = action.payload.sort;
    },
    setParticipantsPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setParticipantDefaults: (
      state,
      action: PayloadAction<{
        search: string;
        page: number;
        filter: string;
        sort: {
          sortBy: string;
          orderBy: string;
        };
        searchType: string;
        type: string;
      }>
    ) => {
      state.selectedTab = action.payload.type;
      state.searchTerm = action.payload.search;
      state.filter = action.payload.filter;
      state.sort = action.payload.sort;
      state.searchType = action.payload.searchType;
      state.currentPage = action.payload.page;
      state.urlLoaded = true;
    },
    setUrlLoadedFalse: (state) => {
      state.urlLoaded = false;
    },
    setParticipantSite: (state) => {
      state.currentPage = 1;
      state.filter = "";
      state.sort = {
        orderBy: "asc",
        sortBy: "screenId",
      };
    },
  },
});

export const {
  setPreScreenedParticipantUsers,
  setScreenedParticipantUsers,
  setParticipantSearchTerm,
  toggleParticipantLoader,
  setParticipantGroups,
  setFilterParticipants,
  setSelectedParticipants,
  setParticipantSearchType,
  setParticipantDefaults,
  setParticipantsPage,
  setParticipantsFilter,
  setParticipantsSort,
  setParticipantsTab,
  setUrlLoadedFalse,
  setParticipantSite,
} = participantSlice.actions;

export default participantSlice.reducer;
