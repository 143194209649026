import { useState } from "react";
import { toastMessage, errorToastMessage } from "../../../helpers/toastMessage";
import { Modal, Button, Radio, Space, DatePicker } from "antd";
import { hideModal, setModalDetails } from "../../../redux/reducers/modalSlice";
import http from "../../../http";
import { useAppDispatch } from "../../../redux/hooks";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import moment from "moment";
import { getStudyId } from "../../../helpers/study";

type Props = {
  show: boolean;
  userId?: string;
  title: string;
  type: string;
};

const DiaryExportModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const [all, setAll] = useState(true);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const changeHandler = (event: any) => {
    setAll(event.target.value);
  };

  const submitHandler = async () => {
    try {
      if (!all) {
        if (!startDate) {
          toastMessage("warning", "Start date cannot be empty");
          return;
        }
        if (!endDate) {
          toastMessage("warning", "End date cannot be empty");
          return;
        }
        const startTime = moment(startDate, "DD/MM/YYYY");
        const endTime = moment(endDate, "DD/MM/YYYY");
        if (startTime >= endTime) {
          toastMessage("warning", "Start date cannot be greater than End date");
          return;
        }
      }
      console.log(startDate, endDate, all);
      dispatch(setAppLoader(true));
      const studyId = getStudyId();
      const res = await http.post(`/study/${studyId}/phase/export-food`);
      const { jobId } = res.data.data;
      dispatch(
        setModalDetails({
          type: `DOWNLOAD_MODAL`,
          modalProps: {
            show: true,
            jobId: jobId,
          },
        })
      );
      dispatch(setAppLoader(false));
    } catch (err) {
      errorToastMessage(err);
      dispatch(setAppLoader(false));
    }
  };

  return (
    <Modal
      title={props.title}
      visible={props.show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div>
        <div className="d-flex mb-5">
          <Radio.Group onChange={changeHandler} value={all}>
            <Space direction="vertical">
              <Radio value={true}>All</Radio>
              <Radio value={false}>Date range</Radio>
            </Space>
          </Radio.Group>
        </div>
      </div>
      {!all && (
        <div className="d-flex mb-5">
          <div style={{ marginRight: "10px", flex: 1, minWidth: "0px" }}>
            <div className="text-secondary font-m fw-medium mb-1">
              Start date
            </div>
            <DatePicker
              className="date-selector"
              format="DD/MM/YYYY"
              value={startDate ? moment(startDate, "DD/MM/YYYY") : null}
              style={{
                borderRadius: "16px",
                height: "40px",
              }}
              onChange={(_1, dateString) => {
                setStartDate(dateString);
              }}
              inputReadOnly
            />
          </div>
          <div style={{ flex: 1, minWidth: "0px" }}>
            <div className="text-secondary font-m fw-medium mb-1">End date</div>
            <DatePicker
              className="date-selector"
              format="DD/MM/YYYY"
              value={endDate ? moment(endDate, "DD/MM/YYYY") : null}
              style={{
                borderRadius: "16px",
                height: "40px",
              }}
              onChange={(_1, dateString) => {
                setEndDate(dateString);
              }}
              inputReadOnly
            />
          </div>
        </div>
      )}
      <div className="d-flex align-items-center">
        <Button
          type="primary"
          htmlType="submit"
          style={{ height: "40px", borderRadius: "12px" }}
          className="me-4"
          onClick={submitHandler}
        >
          <span className="fw-semibold font-sm">Export</span>
        </Button>
        <Button
          style={{ height: "40px", borderRadius: "12px" }}
          className="me-4"
          onClick={closeHandler}
        >
          <span className="fw-semibold font-sm">Cancel</span>
        </Button>
      </div>
    </Modal>
  );
};

export default DiaryExportModal;
