import { Modal, Button, Select } from "antd";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { useAppDispatch } from "../../../redux/hooks";
import { useState } from "react";
import { errorToastMessage, toastMessage } from "../../../helpers/toastMessage";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import http from "../../../http";

type Props = {
  show: boolean;
  incident: string;
  userId: string;
  screenId: string;
  callback: Function;
};

const IncidentModal = (props: Props) => {
  const [incident, setIncident] = useState(props.incident);

  const dispatch = useAppDispatch();

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const changeHandler = (val: string) => {
    setIncident(val);
  };

  const submitHandler = async () => {
    try {
      dispatch(setAppLoader(true));
      await http.patch(`/dashboard`, {
        userId: props.userId,
        incidentContact: incident === "Yes",
        phase: false,
      });
      toastMessage(
        "success",
        "Contact for Incidental Finding updated successfully"
      );
      closeHandler();
      props.callback();
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal
      title={"Contact for Incidental Finding for " + props.screenId}
      visible={props.show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div className="mb-5">
        <div>
          <div className="text-secondary font-m fw-medium mb-1">
            Contact for Incidental Finding
          </div>
          <Select
            className="custom-select-field"
            size="large"
            value={incident}
            onChange={changeHandler}
            placeholder="Select contact option"
          >
            <Select.Option key="yes" value="Yes">
              Yes
            </Select.Option>
            <Select.Option key="no" value="No">
              No
            </Select.Option>
          </Select>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <Button
          type="primary"
          style={{ height: "40px", borderRadius: "12px" }}
          className="me-4"
          onClick={submitHandler}
        >
          <span className="fw-semibold font-sm">Save</span>
        </Button>
        <Button
          style={{ height: "40px", borderRadius: "12px" }}
          className="me-4"
          onClick={closeHandler}
        >
          <span className="fw-semibold font-sm">Cancel</span>
        </Button>
      </div>
    </Modal>
  );
};

export default IncidentModal;
