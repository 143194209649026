import { Modal, Input, Button, Select } from "antd";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { useAppDispatch } from "../../../redux/hooks";
import { useState } from "react";
import { errorToastMessage, toastMessage } from "../../../helpers/toastMessage";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import http from "../../../http";
import { toggleParticipantLoader } from "../../../redux/reducers/participantSlice";

type Props = {
  show: boolean;
  subjectId: string;
  nickname: string;
  callback: Function;
  userId: string;
  lang: string;
  subgroup?: string;
};

const NicknameModal = (props: Props) => {
  const [name, setName] = useState(props.nickname || "");
  const [lang, setLang] = useState(props.lang || "en");
  const dispatch = useAppDispatch();

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const changeHandler = (event: any) => {
    setName(event.target.value);
  };

  const submitHandler = async () => {
    try {
      // if (!name.trim()) {
      //   toastMessage("warning", "Nickname cannot be empty");
      //   return;
      // }
      dispatch(setAppLoader(true));
      const body: any = {
        nickname: name.trim() || undefined,
      };
      if (!props.lang || !props.subgroup) {
        body.lang = lang;
      } else if (!body.nickname) {
        dispatch(setAppLoader(false));
        toastMessage("warning", "Please change values to update");
        return;
      }
      await http.put(`/users/${props.userId}`, body);
      toastMessage("success", "Participant details updated successfully");
      closeHandler();
      dispatch(toggleParticipantLoader());
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal
      title={"Set details for " + props.subjectId}
      visible={props.show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div>
        <div className="col-md-12 mb-4">
          <div className="form-group">
            <div className="text-secondary font-m fw-medium mb-1">Nickname</div>
            <Input
              className="singer-input"
              value={name}
              placeholder="Enter nickname here..."
              onChange={changeHandler}
            />
          </div>
        </div>
      </div>
      {(!props.lang || !props.subgroup) && (
        <div className="mb-3">
          <div>
            <div className="text-secondary font-m fw-medium mb-1">Language</div>
            <Select
              className="custom-select-field"
              size="large"
              value={lang}
              onChange={(val) => setLang(val)}
              placeholder="Select language"
            >
              <Select.Option key="en" value="en">
                English
              </Select.Option>
              <Select.Option key="ch" value="ch">
                Chinese
              </Select.Option>
            </Select>
          </div>
        </div>
      )}
      <div className="d-flex align-items-center">
        <Button
          type="primary"
          style={{ height: "40px", borderRadius: "12px" }}
          className="me-4"
          onClick={submitHandler}
        >
          <span className="fw-semibold font-sm">Save</span>
        </Button>
        <Button
          style={{ height: "40px", borderRadius: "12px" }}
          className="me-4"
          onClick={closeHandler}
        >
          <span className="fw-semibold font-sm">Cancel</span>
        </Button>
      </div>
    </Modal>
  );
};

export default NicknameModal;
