import { Modal, Button, Radio, Space } from "antd";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import { errorToastMessage, toastMessage } from "../../../helpers/toastMessage";
import http from "../../../http";
import { useState } from "react";

const CancelAppointmentModal = () => {
  const [type, setType] = useState(false);
  const dispatch = useAppDispatch();
  const { show, appointment, callback } = useAppSelector(
    (state) => state.modal.modalProps
  );

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const cancelAppointment = async () => {
    try {
      dispatch(setAppLoader(true));
      const url =
        `/ap/${appointment?.id}/cancel` + (type ? "?upcoming=true" : "");
      await http.patch(url);
      toastMessage("success", "Appointment cancelled successfully");
      closeHandler();
      callback();
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  const changeHandler = (event: any) => {
    setType(event.target.value);
  };

  return (
    <Modal
      title={"Cancel Appointment"}
      visible={show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div>
        <div className="d-flex mb-5">
          <Radio.Group onChange={changeHandler} value={type}>
            <Space direction="vertical">
              <Radio value={false}>This Appointment only</Radio>
              <Radio value={true}>This and all upcoming Appointments</Radio>
            </Space>
          </Radio.Group>
        </div>
        <div className="d-flex">
          <Button
            type="primary"
            style={{ height: "40px", borderRadius: "12px" }}
            className="me-4"
            onClick={cancelAppointment}
          >
            <span className="fw-semibold font-sm">Cancel Appointment</span>
          </Button>
          <Button
            onClick={closeHandler}
            style={{ height: "40px", borderRadius: "12px" }}
            className="me-4"
          >
            <span className="fw-semibold font-sm">Cancel</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CancelAppointmentModal;
