import React from "react";
import { useAppSelector } from "../../redux/hooks";

import ViewImage from "./ViewImage/ViewImage";
import RandomizationEntryModal from "./RandomizationEntryModal/RandomizationEntryModal";
import DashboardEntryModal from "./DashboardModals/DashboardEntryModal";
import CancelAppointmentModal from "./CancelAppointmentModal/CancelAppointmentModal";
import ViewAppointmentModal from "./ViewAppointmentModal/ViewAppointmentModal";
import IncidentModal from "./DashboardModals/IncidentModal";
import NicknameModal from "./NicknameModal/NicknameModal";
import TimerModal from "./TimerModal/TimerModal";
import StudyRemoveModal from "./StudyRemoveModal/StudyRemoveModal";
import FoodSubmissionLog from "./ViewFoodVersionModal/ViewFoodVersionModal";
import ExerciseSubmissionLog from "./ViewExerciseVersionModal/ViewExerciseVersionModal";
import DiaryExportModal from "./DiaryExportModal/DiaryExportModal";
import DownloadModal from "./DownloadModal/DownloadModal";
import ViewIncidentVersionModal from "./ViewIncidentVersionModal/ViewIncidentVersionModal";
import ViewMedicineVersionModal from "./ViewMedicineVersionModal/ViewMedicineVersionModal";
// import AddParticipantModal from "./AddParticipantModal/AddParticipantModal";

const LoginCodeModal = React.lazy(
  () => import("./LoginCodeModal/LoginCodeModal")
);

const AddIncidentModal = React.lazy(
  () => import("./AddIncidentModal/AddIncidentModal")
);

const AddToGroupModal = React.lazy(
  () => import("./AddParticipantToGroupModal/AddParticipantToGroupModal")
);

const AssignGroupModal = React.lazy(
  () => import("./AssignParticipantGroupModal/AssignParticipantGroupModal")
);

const AddGroupModal = React.lazy(
  () => import("./AddParticipantGroupModal/AddParticipantGroupModal")
);

const AddExerciseDiaryModal = React.lazy(
  () => import("./AddExerciseModal/AddExerciseModal")
);

const RescheduleAppointmentModal = React.lazy(
  () => import("./RescheduleAppointmentModal/RescheduleAppointmentModal")
);

const QueryCommentModal = React.lazy(
  () => import("./QueryCommentModal/QueryCommentModal")
);

const CRFExportModal = React.lazy(
  () => import("./CRFExportModal/CRFExportModal")
);
const ShowQueryModal = React.lazy(
  () => import("./ShowQueryModal/ShowQueryModal")
);
const ViewQueryModal = React.lazy(
  () => import("./ViewQueryModal/ViewQueryModal")
);

const GCPSubmissionModal = React.lazy(
  () => import("./GCPSubmissionModal/GCPSubmissionModal")
);

const ScheduleAppointmentModal = React.lazy(
  () => import("./ScheduleAppointmentModal/ScheduleAppointmentModal")
);

const AddMedicationModal = React.lazy(
  () => import("./AddMedicationModal/AddMedicationModal")
);

const AddAdminModal = React.lazy(() => import("./AddAdminModal/AddAdminModal"));

const EditAdminModal = React.lazy(
  () => import("./EditAdminModal/EditAdminModal")
);

const AddEducationModule = React.lazy(
  () => import("./AddEducationModule/AddEducationModule")
);
const AddLearningModule = React.lazy(
  () => import("./AddLearningModal/AddLearningModal")
);

const AddPhaseForm = React.lazy(() => import("./AddPhaseForm/AddPhaseForm"));

const CreateDocumetsModal = React.lazy(
  () => import("./CreateDocumentsModal/CreateDocumentsModal")
);

const CreateFoldersModal = React.lazy(
  () => import("./CreateFolderModal/CreateFolderModal")
);

const AddQuestionModal = React.lazy(
  () => import("./AddQuestionModal/AddQuestionModal")
);

const CRFSubmissionLogModal = React.lazy(
  () => import("./CRFSubmissionLogModal/CRFSubmissionLogModal")
);

const CRFScoreModal = React.lazy(() => import("./CRFScoreModal/CRFScoreModal"));

const ChatHyperLinkModal = React.lazy(
  () => import("./ChatHyperlink/ChatHyperlinkModal")
);

const ScorePreview = React.lazy(
  () => import("./ScorePreviewModal/ScorePreviewModal")
);

const AddPatientFoodDiaryModal = React.lazy(
  () => import("./AddPatientFoodDiaryModal/AddPatientFoodDiaryModal")
);

const AddPatientExerciseDiaryModal = React.lazy(
  () => import("./AddPatientExerciseDiaryModal/AddPatientExerciseDiaryModal")
);

const AddParticipantModal = React.lazy(
  () => import("./AddParticipantModal/AddParticipantModal")
);

const MODAL_COMPONENTS: any = {
  ADD_QUESTIONS: AddQuestionModal,
  EDIT_QUESTIONS: AddQuestionModal,
  SCORE_PREVIEW: ScorePreview,
  VIEW_IMAGE: ViewImage,
  SHOW_QUERY: ShowQueryModal,
  ADD_PHASE_FORM: AddPhaseForm,
  ADD_EDUCATION_MODULE: AddEducationModule,
  ADD_LEARNING_MODULE: AddLearningModule,
  TIMER_MODAL: TimerModal,
  ADD_ADMIN_MODAL: AddAdminModal,
  EDIT_PROFILE: EditAdminModal,
  CRF_EXPORT: CRFExportModal,
  CREATE_DOCUMENT: CreateDocumetsModal,
  CREATE_FOLDER: CreateFoldersModal,
  ADD_MEDICATION: AddMedicationModal,
  SCHEDULE_APPOINTMENT: ScheduleAppointmentModal,
  RESCHEDULE_APPOINTMENT: RescheduleAppointmentModal,
  SEND_CHAT_LINK: ChatHyperLinkModal,
  GCP_FORM_SUBMISSION: GCPSubmissionModal,
  VIEW_QUERY: ViewQueryModal,
  QUERY_COMMENT: QueryCommentModal,
  ADD_EXERCISE_MODAL: AddExerciseDiaryModal,
  ADD_PARTICIPANT_GROUP: AddGroupModal,
  ADD_PARTICIPANTS_TO_GROUP: AddToGroupModal,
  ASSIGN_GROUP: AssignGroupModal,
  ADD_INCIDENT: AddIncidentModal,
  VIEW_CRF_LOG: CRFSubmissionLogModal,
  CRF_SCORE_MODAL: CRFScoreModal,
  NICKNAME_MODAL: NicknameModal,
  VIEW_APPOINTMENT: ViewAppointmentModal,
  CANCEL_APPOINTMENT: CancelAppointmentModal,
  SET_INCIDENT_CONTACT: IncidentModal,
  DASHBOARD_ENTRY: DashboardEntryModal,
  STUDY_REMOVE: StudyRemoveModal,
  RANDOMIZATION_ENTRY_MODAL: RandomizationEntryModal,
  ADD_PATIENT_FOOD_DIARY: AddPatientFoodDiaryModal,
  ADD_PATIENT_EXERCISE_DIARY: AddPatientExerciseDiaryModal,
  FOOD_SUBMISSION_LOG: FoodSubmissionLog,
  EXERCISE_SUBMISSION_LOG: ExerciseSubmissionLog,
  DIARY_EXPORT_MODAL: DiaryExportModal,
  DOWNLOAD_MODAL: DownloadModal,
  MEDICATION_SUBMISSION_LOG: ViewMedicineVersionModal,
  INCIDENT_SUBMISSION_LOG: ViewIncidentVersionModal,
  LOGIN_CODE_MODAL: LoginCodeModal,
  ADD_PARTICIPANT: AddParticipantModal,
};

const RootModal = () => {
  const { type, modalProps } = useAppSelector((state) => state.modal);
  if (!type) {
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[type];
  if (SpecificModal) {
    return <SpecificModal {...modalProps} />;
  } else {
    return null;
  }
};

export default RootModal;
