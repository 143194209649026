import { Modal, Button, Select } from "antd";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { useAppDispatch } from "../../../redux/hooks";
import { useState } from "react";
import { errorToastMessage, toastMessage } from "../../../helpers/toastMessage";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import http from "../../../http";

type Props = {
  show: boolean;
  userId: string;
  subjectId: string;
  phaseId: string;
  callback: Function;
  title: string;
  value?: boolean;
  type: string;
  informant?: string;
};

const DashboardEntryModal = (props: Props) => {
  const [val, setVal] = useState(props.value ? "Done" : "Not Done");
  const [informant, setInformant] = useState<string>(props.informant || "");
  const dispatch = useAppDispatch();

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const changeHandler = (val: string) => {
    setVal(val);
  };

  const changeInformantHandler = (val: string) => {
    setInformant(val);
  };

  const submitHandler = async () => {
    try {
      dispatch(setAppLoader(true));
      await http.patch(`/dashboard`, {
        userId: props.userId,
        phaseId: props.phaseId,
        [props.type]:
          props.type === "informant" || props.type === "informantRelationship"
            ? informant
            : val === "Done",
        phase: true,
      });
      toastMessage("success", props.title + " updated successfully");
      closeHandler();
      props.callback();
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal
      title={props.title + " for " + props.subjectId}
      visible={props.show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div className="mb-5">
        <div>
          <div className="text-secondary font-m fw-medium mb-1">
            {props.title}
          </div>
          {props.type !== "informant" &&
          props.type !== "informantRelationship" ? (
            <Select
              className="custom-select-field"
              size="large"
              value={val}
              onChange={changeHandler}
              placeholder="Select"
            >
              <Select.Option key="yes" value="Done">
                Done
              </Select.Option>
              <Select.Option key="no" value="Not Done">
                Not Done
              </Select.Option>
            </Select>
          ) : props.type !== "informant" ? (
            <Select
              className="custom-select-field"
              size="large"
              value={informant}
              onChange={changeInformantHandler}
              placeholder="Select"
            >
              <Select.Option key="spouse/partner" value="Spouse/Partner">
                Spouse/Partner
              </Select.Option>
              <Select.Option key="child/grandchild" value="Child/Grandchild">
                Child/Grandchild
              </Select.Option>
              <Select.Option key="others" value="Others">
                Others
              </Select.Option>
              <Select.Option key="no informant" value="No Informant">
                No Informant
              </Select.Option>
            </Select>
          ) : (
            <Select
              className="custom-select-field"
              size="large"
              value={informant}
              onChange={changeInformantHandler}
              placeholder="Select"
            >
              <Select.Option key="no_consent" value="No Consent">
                No Consent
              </Select.Option>
              <Select.Option key="consent" value="Consent">
                Consent
              </Select.Option>
            </Select>
          )}
        </div>
      </div>
      <div className="d-flex align-items-center">
        <Button
          type="primary"
          style={{ height: "40px", borderRadius: "12px" }}
          className="me-4"
          onClick={submitHandler}
        >
          <span className="fw-semibold font-sm">Save</span>
        </Button>
        <Button
          style={{ height: "40px", borderRadius: "12px" }}
          className="me-4"
          onClick={closeHandler}
        >
          <span className="fw-semibold font-sm">Cancel</span>
        </Button>
      </div>
    </Modal>
  );
};

export default DashboardEntryModal;
